import { Inject, Injectable } from '@angular/core';
import { RoleModel, RoleRequestModel } from '../_models/role.model';
import { HttpMethodsService } from './http-methods.services';
import { map, Observable } from 'rxjs';
import { UserModel, UserRequestModel } from '../_models/user.model';
import { PersonModel, PersonRequestModel } from '../_models/person.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _httpService: HttpMethodsService) { }

  login(username: string, password: string) {
    return this._httpService.post('login', {
      username,
      password,
    });
  }

  me(): Observable<UserModel> {
    return this._httpService.get('auth');
  }

  checkUsername(username: string) {
    return this._httpService.post('auth_check', { username });
  }

  register(data: UserRequestModel) {
    return this._httpService.post('auth_register', data);
  }

  update(data: PersonRequestModel) {
    return this._httpService.patch('auth', data);
  }

  forgot(email: string) {
    console.log(email)
    return this._httpService.post('auth_forgot', { email });
  }

  reset(hash: string, password: string) {
    return this._httpService.post('auth_reset', { hash, password });
  }


}
